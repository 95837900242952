import { Button, IconButton, Modal } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { DragEvent, useRef, useState } from 'react';
import { predictImgLabel } from 'services/api';
import classes from './ImgClassifier.module.css';
interface LabelPrediction {
  Melanoma: number[];
  Nevus: number[];
}
function ImgClassifier({ open, setOpen }) {
  const [img, setimg] = useState('');
  const inputFile = useRef<HTMLInputElement>(null);
  const [file, setFile] = useState<File>();
  const [label, setlabel] = useState<LabelPrediction | null>();
  const [dragged, setdragged] = useState(false);

  const handleClear = (ev) => {
    setimg('');
    setlabel(undefined);

    setFile(undefined);
    if (inputFile.current) {
      inputFile.current.value = '';
    }
  };

  const previewImg = (file: File) => {
    if (file) {
      const reader = new FileReader();

      reader.readAsDataURL(file);
      setFile(file);
      reader.onloadend = () => setimg(reader.result as string);
    }
  };
  const handleChange = (ev) => {
    if (inputFile.current && inputFile.current?.files) {
      const file = inputFile.current.files[0];

      previewImg(file);
    }
  };
  const handleSubmit = (ev) => {
    ev.preventDefault();
    setlabel(null);

    const formData = new FormData();
    if (file) {
      formData.append('file', file);

      predictImgLabel<LabelPrediction>(formData).then(setlabel);
    }
  };
  const handleDragOver = (ev: DragEvent<Element>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setdragged(true);
  };
  const handleDragLeave = (ev: DragEvent<Element>) => {
    ev.preventDefault();
    ev.stopPropagation();
    setdragged(false);
  };

  const handleDrop = (ev: DragEvent<Element>) => {
    ev.preventDefault();
    ev.stopPropagation();

    const file = ev.dataTransfer.files[0];

    if (file && file.type.startsWith('image/jp')) {
      previewImg(file);
    }
    setdragged(false);
  };

  let result;

  if (label !== undefined) {
    if (label !== null) {
      result = 'Loading ...';
      result =
        label.Nevus[0] > label.Melanoma[0]
          ? ` Diagnosis: Nevus with a probability of ${(
              label.Nevus[0] * 100
            ).toFixed(2)}%`
          : ` Diagnosis: Melanoma with a probability of ${(
              label.Melanoma[0] * 100
            ).toFixed(2)}%`;
    }
  }

  return (
    <>
      {open && (
        <Modal
          open={open}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
          onClose={() => {
            setimg('');
            setOpen(false);
          }}
        >
          <form className={classes.card} onSubmit={handleSubmit}>
            {img ? (
              <>
                <div style={{ position: 'relative', marginTop: '2em' }}>
                  <IconButton
                    style={{
                      position: 'absolute',
                      top: '-1.2em',
                      left: '-1.2em'
                    }}
                    onClick={handleClear}
                    color="secondary"
                  >
                    <HighlightOffIcon
                      style={{
                        fontSize: '30px'
                      }}
                    />
                  </IconButton>
                  <img
                    loading="lazy"
                    src={img}
                    style={{
                      maxHeight: '280px',
                      width: '95%',
                      borderRadius: '20px',
                      margin: '1em 1em'
                    }}
                  />
                </div>
                <div style={{ textAlign: 'center', height: '2em' }}>
                  {result}
                </div>
                <Button
                  type="submit"
                  className={classes.submitButton}
                  color="primary"
                  variant="contained"
                >
                  Prognostic
                </Button>
              </>
            ) : (
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                onDragLeave={handleDragLeave}
                style={{
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  height: 300,
                  display: 'flex',
                  borderRadius: '1em',
                  padding: '1em',
                  border: `3px dashed ${!dragged ? 'grey' : 'blue'}`,
                  margin: '0',
                  backgroundColor: 'var(--background)'
                }}
              >
                <div
                  style={{
                    fontSize: '22px',
                    color: '#444444',
                    gap: '1.2em',
                    textAlign: 'center',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <div>Drag an image</div>
                  <div style={{ fontSize: '0.95rem' }}> - or - </div>

                  <Button
                    onClick={() => {
                      inputFile.current?.click();
                    }}
                    color="primary"
                    variant="contained"
                    style={{
                      border: 0,
                      borderRadius: '2em',
                      padding: '8px 32px',
                      fontSize: '1.05rem',

                      textTransform: 'capitalize'
                    }}
                  >
                    Select Image
                  </Button>
                  <input
                    ref={inputFile}
                    type="file"
                    accept="image/jpeg"
                    style={{ display: 'none' }}
                    onChangeCapture={handleChange}
                  />
                </div>
              </div>
            )}
          </form>
        </Modal>
      )}
    </>
  );
}

export default ImgClassifier;
