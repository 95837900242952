import { Filter } from '@cubejs-client/core';
import { Token, UserProfile } from 'models/User';
import { createContext, Dispatch, SetStateAction } from 'react';

export const userContext = createContext<{
  profile: UserProfile;
  setProfile: any;
  token: Token;
}>(
  {} as {
    profile: UserProfile;
    setProfile: any;
    token: Token;
  }
);

export enum FilterType {
  Manual,
  Search,
  Graph
}

export type CustomFilter = {
  cubejsFilter: Filter;
  id: string;
  visible: boolean;
  type: FilterType;
};

export const userProvider = userContext.Provider;

export const filtersContext = createContext<
  [CustomFilter[], Dispatch<SetStateAction<CustomFilter[]>>]
>([
  [],
  (v) => {
    window;
  }
]);

export const filtersProvider = filtersContext.Provider;
