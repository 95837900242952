import {
  createTheme,
  StylesProvider,
  ThemeProvider
} from '@material-ui/core/styles';
import * as Sentry from '@sentry/react';
import { Integrations as SentryIntegrations } from '@sentry/tracing';
import ReactDOM from 'react-dom';
import { FlagsProvider } from 'react-feature-flags';
import reportWebVitals from 'reportWebVitals';
import 'styles/index.css';
import App from './App';

const flags = [
  { name: 'timeLine', isActive: process.env.NODE_ENV === 'development' }
];
if (
  process.env.REACT_APP_SENTRY_DSN &&
  process.env.REACT_APP_SENTRY_ENVIRONMENT
) {
  const sentryDSN = String(process.env.REACT_APP_SENTRY_DSN);

  Sentry.init({
    dsn: sentryDSN,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    integrations: [new SentryIntegrations.BrowserTracing()],
    tracesSampleRate: 1.0
  });
}

const theme = createTheme({
  palette: {
    primary: {
      main: '#0b468c'
    },
    secondary: {
      main: '#8c00a5'
    }
  }
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={'An error has occurred'}>
    <FlagsProvider value={flags}>
      <StylesProvider injectFirst>
        <ThemeProvider theme={theme}>
          <App />
        </ThemeProvider>
      </StylesProvider>
    </FlagsProvider>
  </Sentry.ErrorBoundary>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
