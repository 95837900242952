import {
  AppBar,
  Avatar,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemAvatar,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Popover,
  useMediaQuery
} from '@material-ui/core';
import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import { Autocomplete } from '@material-ui/lab';
import ImgClassifier from 'components/ImgClassifier';
import { Patient } from 'models/Patient';
import { useContext, useEffect, useState } from 'react';
import logo from 'resources/logo.svg';
import { searchPatient } from 'services/api';
import { logout } from 'services/auth';
import { userContext } from 'utils/contexts';
import { useLocation, useRoute } from 'wouter';
import classes from './Header.module.css';
import MenuButton from './MenuButton';

const useStyles = makeStyles((theme) => ({
  muiappBar: {
    zIndex: theme.zIndex.drawer + 1,
    backgroundColor: '#fff ',
    height: '90px'
  }
}));

function Header({ open, setOpen }): JSX.Element {
  const [openMenu, setOpenMenu] = useState(false);
  const [loading, setLoading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [openForm, setOpenForm] = useState(false);

  const [search, setSearch] = useState('');
  const [location, setLocation] = useLocation();
  const [patients, setPatients] = useState<Patient[]>([]);
  const [match, params] = useRoute('/patient/:id');

  const matches = useMediaQuery('(max-width:1380px)');

  const { profile: user } = useContext(userContext);

  const muiclasses = useStyles();

  useEffect(() => {
    if (search.trim().length > 0) {
      setLoading(true);
      searchPatient(search).then((pl) => {
        setLoading((_) => {
          setPatients(pl);
          return false;
        });
      });
    } else {
      setPatients([]);
      setLoading(true);
    }
  }, [search]);

  const handleSelect = (event) => {
    setOpenMenu(!openMenu);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpenMenu(false);
  };

  const onLogout = (ev) => {
    logout();

    setLocation('/login');
  };

  return (
    <>
      <AppBar position="fixed" className={muiclasses.muiappBar}>
        <div
          style={{
            padding: 0,

            minHeight: '90px',
            alignItems: 'center',
            display: 'flex'
          }}
        >
          <div
            style={{
              width: !matches ? '20.5rem' : 'auto',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            {matches && <MenuButton open={open} setOpen={setOpen} />}
            <div
              style={{ textDecoration: 'none', cursor: 'pointer' }}
              onClick={() => setLocation('/')}
            >
              <img className={classes.title} src={logo} />
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',

              width: '100%'
            }}
          >
            <div
              className={classes.searchwrapper}
              style={{
                display: match ? 'flex' : 'none',
                maxWidth: '1550px'
              }}
            >
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <Autocomplete
                autoComplete
                freeSolo
                fullWidth
                clearOnEscape
                id="patients-complete"
                style={{}}
                options={patients}
                loading={loading}
                getOptionLabel={(option) => `${option.name} ${option.surname}`}
                renderOption={(option, state) => (
                  <ListItem
                    onClick={() => {
                      setSearch('');
                      setLocation(`/patient/${option.id}`);
                    }}
                    style={{
                      width: '100%',
                      fontSize: '1.1rem'
                    }}
                  >
                    {`${option.name} ${option.surname} (${option.priority})`}
                  </ListItem>
                )}
                renderInput={(params) => (
                  <InputBase
                    className={classes.inputRoot}
                    id={params.id}
                    inputProps={params.inputProps}
                    inputRef={params.InputProps.ref}
                    value={search}
                    onChange={(ev) => setSearch(ev.target.value)}
                    type="search"
                    placeholder="Search"
                    classes={{
                      input: classes.inputInput,
                      root: classes.inputRoot
                    }}
                  />
                )}
              />
            </div>
          </div>
          <div
            className={classes.toolbar}
            style={{
              float: 'right'
            }}
          >
            <div className={classes.user}>
              <div style={{ cursor: 'pointer' }} onClick={handleSelect}>
                <Avatar src={user?.avatar} />
              </div>
              <Popover
                anchorEl={anchorEl}
                onClose={handleClose}
                open={openMenu}
                id="popover"
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center'
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center'
                }}
              >
                <Paper>
                  <List>
                    <ListItem style={{ height: 80, width: 280 }}>
                      <ListItemAvatar>
                        <Avatar src={user?.avatar} />
                      </ListItemAvatar>

                      <ListItemText
                        style={{ fontWeight: 600, cursor: 'default' }}
                      >
                        {`${user?.user?.first_name} ${user?.user?.last_name}`}
                      </ListItemText>
                    </ListItem>
                    <Divider variant="fullWidth" component="li" />

                    <ListItem
                      button
                      onClick={() => {
                        setOpenMenu(false);
                        setOpenForm(true);
                      }}
                    >
                      <ListItemIcon>
                        <CameraAltOutlinedIcon />
                      </ListItemIcon>
                      Identify Image
                    </ListItem>

                    <ListItem button component="a" onClick={onLogout}>
                      <ListItemIcon>
                        <ExitToAppIcon />
                      </ListItemIcon>
                      Sign out
                    </ListItem>
                  </List>
                </Paper>
              </Popover>
            </div>
          </div>
        </div>
      </AppBar>

      <ImgClassifier open={openForm} setOpen={setOpenForm} />
    </>
  );
}
export default Header;
