import { IconButton } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';

function MenuButton({ open, setOpen }) {
  return (
    <IconButton
      style={{ color: '#adadad' }}
      aria-label="open drawer"
      onClick={() => setOpen((v) => !v)}
      edge="start"
    >
      <MenuIcon />
    </IconButton>
  );
}

export default MenuButton;
