import Modal from '@material-ui/core/Modal';
import * as Sentry from '@sentry/react';
import Page from 'components/UI/Page';
import Login from 'pages/Login';
import Timeline from 'pages/Timeline';
import { lazy, Suspense } from 'react';
import { Flags } from 'react-feature-flags';
import { Route, Switch, useLocation } from 'wouter';

const PatientPage = lazy(() => import('pages/Patient'));
const Home = lazy(() => import('pages/Home'));

function App() {
  const [location, setLocation] = useLocation();

  return (
    <Switch>
      <Route path="/login">
        <div
          style={{
            backgroundImage: 'url(/bubbles.jpg)',
            backgroundSize: 'cover',
            filter: 'blur(2px)',

            height: '100vh'
          }}
        >
          <Modal
            open
            aria-labelledby="Login Form"
            aria-describedby="Login Form, introduce user and password"
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Login />
          </Modal>
        </div>
      </Route>

      <Suspense fallback={<div>Loading...</div>}>
        <Page>
          <Route path="/" component={Home} />
          <Route path="/patient/:id" component={PatientPage} />
          <Flags
            authorizedFlags={['timeLine']}
            renderOn={(authorizedFlags) => (
              <Route path="/testTimline" component={Timeline} />
            )}
          />
        </Page>
      </Suspense>
    </Switch>
  );
}

export default Sentry.withProfiler(App);
