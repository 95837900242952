import { Button, FormControl, Grid, TextField } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import logo from 'resources/logo.svg';
import { login, saveToken } from 'services/auth';
import { useLocation } from 'wouter';
import classes from './Login.module.css';

function Login(props, ref) {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [error, seterror] = useState<string | null>(null);

  const [location, setLocation] = useLocation();

  const handleSubmit = (ev) => {
    ev.preventDefault();
    seterror(null);
    login({ username, password })
      .then((resp) => {
        console.log(resp);
        saveToken(resp.access, resp.refresh);
        setLocation('/');
      })
      .catch((err) => {
        seterror(String(err));
      });
  };
  return (
    <div className={classes.card}>
      <div className={classes.iconContainer}>
        <img loading="lazy" className={classes.icon} src={logo} />
      </div>

      <form className={classes.form} onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                value={username}
                onChange={(ev) => setUsername(ev.target.value)}
                label="User Name"
                variant="outlined"
                id="username"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <FormControl className={classes.formControl}>
              <TextField
                required
                value={password}
                onChange={(ev) => setPassword(ev.target.value)}
                label="Password"
                variant="outlined"
                type="password"
                id="password"
              />
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" className={classes.submitButton}>
              Sign In
            </Button>
          </Grid>
          {error && (
            <Grid item xs={12}>
              <Alert variant="filled" severity="error">
                {error}
              </Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </div>
  );
}

export default React.forwardRef(Login);
