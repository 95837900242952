import { useMediaQuery } from '@material-ui/core';
import Authenticated from 'components/Authenticated';
import Header from 'components/UI/Header';
import SideBar from 'components/UI/SideBar';
import { ReactNode, useState } from 'react';
import { useLocation, useRoute } from 'wouter';
import classes from './Page.module.css';
function Page(props: { children: ReactNode }) {
  const [location, setLocation] = useLocation();
  const matches = useMediaQuery('(max-width:1380px)');
  const [matchLocation, params] = useRoute('/patient/:id');
  const [open, setOpen] = useState(matchLocation);

  return (
    <Authenticated>
      {location !== '/' && (
        <SideBar open={open || !matches} setOpen={setOpen} />
      )}
      <div className={classes.root}>
        <Header open={open} setOpen={setOpen} />

        <main
          style={{
            marginLeft: !matches && matchLocation ? '280px' : '0',
            width: !matches && matchLocation ? 'calc(100% - 280px)' : '100%'
          }}
          className={classes.content}
        >
          {props.children}
        </main>
      </div>
    </Authenticated>
  );
}

export default Page;
