import axios, { AxiosInstance } from 'axios';
import { Patient } from 'models/Patient';
import { UserProfile } from 'models/User';
import { isCorrectRefreshError, logout, refreshToken } from 'services/auth';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN, API_URL } from 'utils/constants';
type TaskStatus = 'SUCCESS' | 'FAILURE' | 'PENDING';
type Task<T> = { task_result: T; task_status: TaskStatus };

export class API {
  private static request: AxiosInstance;
  public static errorInterceptor(apiRequest, error) {
    const originalRequest = error.config;
    const status = error?.response?.status;
    if (isCorrectRefreshError(status)) {
      return refreshToken({
        refresh: localStorage.getItem(KEY_REFRESH_TOKEN) as string
      })
        .then((data) => {
          const headerAuthorization = `Bearer ${localStorage.getItem(
            KEY_ACCESS_TOKEN
          )}`;

          apiRequest.defaults.headers['Authorization'] = headerAuthorization;
          originalRequest.headers['Authorization'] = headerAuthorization;
          return apiRequest(originalRequest);
        })
        .catch((error) => {
          // if token refresh fails, logout the user to avoid potential security risks.
          logout();
          return Promise.reject(error);
        });
    }
    return Promise.reject(error);
  }
  public static getInstance(ctype = 'application/json') {
    this.request = axios.create({
      timeout: 5000,
      headers: {
        Authorization: `Bearer ${localStorage.getItem(KEY_ACCESS_TOKEN)}`,
        'Content-Type': ctype
      }
    });

    API.request.interceptors.response.use(
      (response) => response, // this is for all successful requests.
      (error) => {
        //handle the request
        console.error(error);
        return this.errorInterceptor(API.request, error);
      }
    );
    return API.request;
  }
}

export function getTaskStatus<T = any>(
  taskId: string,
  retries = 5
): Promise<T> {
  return API.getInstance()
    .get<Task<T>>(`${API_URL}/api/survival/get_task_status/${taskId}/`)
    .then((res) => res.data)
    .then((res) => {
      if (
        res.task_status === 'SUCCESS' ||
        res.task_status === 'FAILURE' ||
        (retries === 0 && res.task_status) === 'PENDING'
      ) {
        return res.task_result as T;
      } else {
        const promise = new Promise((resolve) => {
          return setTimeout(resolve, 200);
        });
        return promise.then(() => getTaskStatus(taskId, retries - 1));
      }
    })
    .catch((err) => console.error(err));
}

export function predictImgLabel<T>(img) {
  return API.getInstance('multipart/form-data')
    .post('${API_URL}/api/survival/predict_from_image/melanoma/', img)
    .then((value) => {
      return getTaskStatus<T>(value.data.task_id);
    });
}

export function getPatients(page: number) {
  return API.getInstance()
    .get<{ results: Patient[]; count: number }>(
      `${API_URL}/api/patients/?page=${page}`
    )
    .then((res) => res.data)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function getPatient(id: string) {
  return API.getInstance()
    .get<Patient>(`${API_URL}/api/patients/${id}/`)
    .then((res) => res.data)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function getUserDetails(id: number) {
  return API.getInstance()
    .get<UserProfile>(`${API_URL}/api/user-profile/${id}/`)
    .then((res) => res.data)
    .catch((error) => {
      return Promise.reject(error);
    });
}
export function updateLayout(id: number, userProfile: Partial<UserProfile>) {
  return API.getInstance()
    .patch<UserProfile>(`${API_URL}/api/user-profile/${id}/`, userProfile)
    .then((res) => res.data)
    .catch((error) => {
      return Promise.reject(error);
    });
}

export function searchPatient(q: string) {
  return API.getInstance()
    .get<{ results: Patient[] }>(`${API_URL}/api/patients/?search=${q}`)
    .then((res) => res.data.results)
    .catch((error) => {
      return Promise.reject(error);
    });
}
