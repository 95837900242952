import { Token, UserProfile } from 'models/User';
import { ReactNode, useEffect, useState } from 'react';
import { getUserDetails } from 'services/api';
import { decodeToken, refreshToken } from 'services/auth';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN } from 'utils/constants';
import { CustomFilter, filtersContext, userContext } from 'utils/contexts';
import { useLocation } from 'wouter';
function Authenticated({ children }: { children: ReactNode }) {
  const [auth, setAuth] = useState(false);
  const [location, setLocation] = useLocation();

  const [user, setUser] = useState<UserProfile>({} as UserProfile);

  const [filters, setFilters] = useState<CustomFilter[]>([]);
  const [decodedToken, setDecodedToken] = useState<Token>({} as Token);

  useEffect(() => {
    const refresh = localStorage.getItem(KEY_REFRESH_TOKEN);
    refresh
      ? refreshToken({ refresh })
          .then((res) => {
            if (!res) {
              return setLocation('/login');
            }
            const token = localStorage.getItem(KEY_ACCESS_TOKEN)!;
            const decoded = decodeToken(token);

            getUserDetails(decoded.profile_id).then((v) => {
              setUser(v);
              setDecodedToken(decoded);
              setAuth(true);
            });
          })
          .catch((error) => {
            console.error(error);
            setAuth(false);
            setLocation('/login');
          })
      : setLocation('/login');
  }, []);

  return auth ? (
    <userContext.Provider
      value={{ profile: user, token: decodedToken, setProfile: setUser }}
    >
      <filtersContext.Provider value={[filters, setFilters]}>
        {children}
      </filtersContext.Provider>
    </userContext.Provider>
  ) : null;
}

export default Authenticated;
