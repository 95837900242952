import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Token } from 'models/User';
import { KEY_ACCESS_TOKEN, KEY_REFRESH_TOKEN, API_URL } from 'utils/constants';

type LoginForm = {
  username: string;
  password: string;
};

type ResponseLoginForm = { access: string; refresh: string };

const tokenRequest = axios.create({
  timeout: 5000,
  headers: {
    'Content-Type': 'application/json',
    accept: 'application/json'
  }
});
export function login(data: LoginForm): Promise<ResponseLoginForm> {
  return tokenRequest
    .post(`${API_URL}/api/token/`, data)
    .then((res) => res.data)
    .catch((error) => {
      console.error(error);
      logout();
      return Promise.reject(error);
    });
}
export function refreshToken(data: { refresh: string }) {
  return tokenRequest
    .post(`${API_URL}/api/token/refresh/`, data)
    .then((res) => res.data)
    .then((res) => {
      localStorage.setItem(KEY_ACCESS_TOKEN, res.access);
      return res.access;
    })
    .catch((error) => {
      console.error(error);

      return Promise.reject(error);
    });
}

export const isCorrectRefreshError = (status) =>
  status === 401 || status === 403;

export const saveToken = (accesToken, refreshToken) => {
  localStorage.setItem(KEY_ACCESS_TOKEN, accesToken);
  localStorage.setItem(KEY_REFRESH_TOKEN, refreshToken);
};

export const logout = () => {
  localStorage.clear();
};

export function decodeToken(token: string) {
  const decoded = jwtDecode<Token>(token as string);
  return decoded;
}
