import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  Toolbar,
  useMediaQuery
} from '@material-ui/core';
import BarChartIcon from '@material-ui/icons/BarChart';
import CalendarTodayIcon from '@material-ui/icons/CalendarToday';
import EmojiObjectsOutlinedIcon from '@material-ui/icons/EmojiObjectsOutlined';
import HomeOutlinedIcon from '@material-ui/icons/HomeOutlined';
import { Patient } from 'models/Patient';
import { useEffect, useState } from 'react';
import logo from 'resources/logo-dark-mode.svg';
import { getPatient } from 'services/api';
import { Link, useLocation, useRoute } from 'wouter';
import MenuButton from './MenuButton';
import classes from './SideBar.module.css';
function SideBar({ open, setOpen }) {
  const [location, setLocation] = useLocation();
  const matches = useMediaQuery('(max-width:1380px)');

  const [match, params] = useRoute('/patient/:id');
  const [patient, setPatient] = useState<Patient>();

  useEffect(() => {
    if (match && params) {
      getPatient(params.id).then((v) => {
        setPatient(v);
      });
    }
  }, [params?.id]);

  return (
    <>
      <Drawer
        className={classes.drawer}
        onClose={(ev) => setOpen(!open)}
        open={open}
        variant={matches ? 'temporary' : 'persistent'}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar style={{ minHeight: 90 }}>
          {matches && <MenuButton open={open} setOpen={setOpen} />}
          <div
            style={{ textDecoration: 'none', cursor: 'pointer' }}
            onClick={() => setLocation('/')}
          >
            <img loading="lazy" style={{ width: '86px' }} src={logo} />
          </div>
        </Toolbar>
        <div className={classes.drawerContainer}>
          <List
            style={{
              display: 'flex',
              padding: '2.5em 0 1.2em 0',
              flexDirection: 'column'
            }}
          >
            <Link to="/">
              <ListItem className={classes.items} button>
                <ListItemIcon>
                  <HomeOutlinedIcon />
                </ListItemIcon>
                <span>Home</span>
              </ListItem>
            </Link>
            <a href="#statusSnapshot">
              <ListItem className={classes.items} button>
                <ListItemIcon>
                  <EmojiObjectsOutlinedIcon />
                </ListItemIcon>
                <span>Status Snapshot</span>
              </ListItem>
            </a>
            <a href="#journey">
              <ListItem className={classes.items} button>
                <ListItemIcon>
                  <CalendarTodayIcon />
                </ListItemIcon>
                <span>Patient's Journey</span>
              </ListItem>
            </a>
            <a href="#prognosis">
              <ListItem className={classes.items} button>
                <ListItemIcon>
                  <BarChartIcon />
                </ListItemIcon>
                <span>Prognosis</span>
              </ListItem>
            </a>
          </List>
          {patient && (
            <div
              style={{
                color: 'white',
                border: '1px solid var(--light-blue-opacity) ',
                borderRadius: '1em',
                boxShadow: 'inset 0 0 1px 2px var(--light-blue-opacity) ',
                padding: '0.5em',
                margin: '1.5em 0.7em '
              }}
            >
              <div
                style={{
                  fontSize: '1.2rem',
                  padding: '0.5em 0',
                  fontWeight: 700,
                  textAlign: 'center'
                }}
              >
                Patient Details
              </div>
              <div style={{ margin: '1em 0', fontSize: '0.9em' }}>
                <div
                  style={{
                    padding: '0.8em 0.4em',
                    borderBottom: '1px solid var(--light-blue-opacity)',
                    textTransform: 'capitalize'
                  }}
                >
                  <div
                    style={{ width: 'max-content', display: 'inline-block' }}
                  >
                    Gender
                  </div>
                  <div style={{ float: 'right', display: 'inline-block' }}>
                    {' '}
                    {patient.patient_gender}
                  </div>
                </div>
                <div
                  style={{
                    padding: '0.8em 0.4em',
                    borderBottom: '1px solid var(--light-blue-opacity)',
                    textTransform: 'capitalize'
                  }}
                >
                  <div
                    style={{ width: 'max-content', display: 'inline-block' }}
                  >
                    Age
                  </div>
                  <div style={{ float: 'right', display: 'inline-block' }}>
                    {' '}
                    {patient.current_age}
                  </div>
                </div>
                <div
                  style={{
                    padding: '0.8em 0.4em',
                    borderBottom: '1px solid var(--light-blue-opacity)',
                    textTransform: 'capitalize'
                  }}
                >
                  <div
                    style={{ width: 'max-content', display: 'inline-block' }}
                  >
                    Allergies
                  </div>
                  <div style={{ float: 'right', display: 'inline-block' }}>
                    {' '}
                    {'No'}
                  </div>
                </div>
                <div
                  style={{
                    padding: '0.8em 0.4em',

                    textTransform: 'capitalize'
                  }}
                >
                  <div
                    style={{ width: 'max-content', display: 'inline-block' }}
                  >
                    Comorbidites
                  </div>
                  <div style={{ float: 'right', display: 'inline-block' }}>
                    {' '}
                    {'Yes'}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </Drawer>
    </>
  );
}

export default SideBar;
